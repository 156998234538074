import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getMyServicePoints = service_unit => api({
  method: 'clinical.clinical.doctype.service_point.service_point.get_unit_service_points',
  args: {
    service_unit,
  },
})

export const getMyLocation = () => api({
  method: 'clinical.api.queue_management.queue_logistics.get_my_location',
  args: {},
})
