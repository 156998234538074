const actions = {
  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateTable({ commit }, payload) {
    commit('UPDATE_TABLE_DATA', payload)
  },
  updateServicesBilling({ commit }, newServicesBilling) {
    commit('updateServicesBilling', newServicesBilling)
  },
}

export default actions
