import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { io } from 'socket.io-client'
import useJwt from '@/auth/jwt/useJwt'
import { format } from 'date-fns'
import base from './@core/auth/jwt/base'

export const BootstrapVueToasterMixin = {
  data() {
    return {
      socket: null,
      isSmallDevice: window.innerWidth <= 576
    }
  },
  mounted() {
    // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true);

    if (!window.socket) {
      this.createSocket()
    }
  

  },
  computed: {
    session() {
      const userData = localStorage.getItem('userData')
      if (userData != null) {
        const { full_name, email } = JSON.parse(userData)
        return { full_name, user: email }
      }
      return {}
    },
    currentRouteName() {
      return this.$route.name
    },
    currentSelectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {

    handleResize() {
      this.isSmallDevice = window.innerWidth <= 576;  // Adjust the threshold as needed
    },
    getUserSession() {
      const userData = localStorage.getItem('userData')
      if (userData != null) {
        const { full_name, email } = JSON.parse(userData)
        return { full_name, user: email }
      }
      return {}
    },
    hasRole(role) {
      const myRoles = window.roles || []
      return myRoles.includes(role)
    },
    checkSocket() {
      console.log('check socket', window.socket)
    },
    msgprint(data) {
      this.$toast({
        component: ToastificationContent,
       
        
        props: {
          title: data.title || '',
          icon: 'InfoIcon',
          variant: 'success',
          text: data.message || data,
        },
        
      }, {
        position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
      })
    },
    show_alert(message) {
      this.$toast({
        component: ToastificationContent,
        position: window.innerWidth <= 576 ? 'top-full' : 'top-right',
        props: {
          title: '',
          icon: 'InfoIcon',
          variant: 'success',
          text: message,
        },
      },  {
        position: window.innerWidth <= 576 ? 'bottom-center' : 'top-right',
      })
    },
    docGetValue(doctype, filters, fieldname) {
      return this.api('frappe.client.get_value', { doctype, filters, fieldname })
    },
    docGetList(doctype, fields, filters) {
      return new Promise((resolve, reject) => this.api('frappe.client.get_list', { doctype, fields , filters})
        .then(response => resolve(response.message))
        .catch(error => reject(error)))
    },
    docGetSingleValue(doctype, field) {
      return new Promise((resolve, reject) => this.api('frappe.client.get_single_value', { doctype, field })
        .then(response => resolve(response.message))
        .catch(error => reject(error)))
    },

    docSave(doc) {
      return new Promise((resolve, reject) => this.directApi('frappe.client.save', {doc, action: "Save"})
        .then(response => resolve(response.message))
        .catch(error => reject(error)))
    },
    docSetValue(doctype, name, fieldname, value) {
      return this.api('frappe.client.set_value', {
        doctype, name, fieldname, value,
      })
    },
    docDocCount(doctype, filters, fields, distinct = false) {
      return this.directApi('frappe.desk.reportview.get_count', {
        doctype, filters, fields, distinct,
      })
    },
    getDocPaginatedList(doctype, filters, fields, start = 0, page_length = 20, view = "List", with_comment_count = false) {
      return this.directApi('frappe.desk.reportview.get', {
        doctype, filters, fields, start, page_length, view, with_comment_count
      })
    },
  convertMessageToKeyValueArray(message) {
      const keys = message.keys;
      const values = message.values[0]; // Assuming there is only one set of values in the array
  
      if (keys.length !== values.length) {
          throw new Error("Keys and values arrays must have the same length");
      }
  
      const keyValueArray = keys.map((key, index) => {
          return { [key]: values[index] };
      });
  
      return keyValueArray;
  },
    docGetVal(doctype, filters, fieldname) {
      return new Promise((resolve, reject) => this.api('frappe.client.get_value', { doctype, filters, fieldname })
        .then(response => resolve(response.message[fieldname]))
        .catch(error => reject(error)))
    },
    goToDesk() {
      const url = `${window.location.protocol}//${window.location.hostname}/app/`
      window.location.href = url;
    },
    async getRoles() {
      if (window.roles == null) {
        window.roles = await this.getUserRoles()
      }
    },
    getUserRoles() {
      return new Promise((resolve, reject) => this.api('frappe.core.doctype.user.user.get_roles', { uid: this.getUserSession().user })
        .then(response => resolve(response.message))
        .catch(error => reject(error)))
    },

    api(method, args) {
      if (typeof method === 'string' || method instanceof String) {

      } else {
        const holder = method
        method = method.method
        args = holder.args
      }

      console.log('Args', args, 'method', method)
      return new Promise((resolve, reject) => useJwt.api(method, args)
        .then(response => resolve(response.data))
        .catch(error => reject(error)))
    },
    setTitle(value) {
      this.$store.dispatch('context/setCurrentRoute', value)
    },
    directApi(method, args) {
      if (typeof method === 'string' || method instanceof String) {

      } else {
        const holder = method
        method = method.method
        args = holder.args
      }

      console.log('Args', args, 'method', method)
      return new Promise((resolve, reject) => useJwt.direct_api(method, args)
        .then(response => resolve(response.data))
        .catch(error => reject(error)))
    },
    formatDate(creationDate) {
      const formattedDate = format(new Date(creationDate), 'MMMM dd yyyy, h:mm:ss a')
      return formattedDate
    },
    get_full_url(url) {
      if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
        return url;
      }
      return url.substr(0, 1) === "/"
        ? base.baseUrl + url
        : base.baseUrl + "/" + url;
    },
    createSocket() {
      const socket = io.connect('http://127.0.0.1:9000', {
        withCredentials: true,
        reconnectionAttempts: 30,
        cors: false,
      })
      this.socket = socket
      window.socket = socket

      socket.on('error', error => {
        alert('error')
      })

      // Connect event
      socket.on('connect', () => {
        console.log('Connected to Frappe server')
        alert('Connected')

        // Send a test message
        socket.emit('test', 'Hello from Socket.IO client')
      })

      // Custom event handler
      socket.on('custom-event', data => {
        console.log('Received custom event:', data)
      })
      socket.on('msgprint', function (message) {
        this.show_alert(message)
      })

      // Disconnect event
      socket.on('disconnect', () => {
        console.log('Disconnected from Frappe server')
      })
    },
    convertToObject(keys, values) {
      const result = {};
      
      if (keys && values && keys.length === values[0].length) {
          for (let i = 0; i < keys.length; i++) {
              result[keys[i]] = values[0][i];
          }
      }
      
      return result;
  }

  },
}
