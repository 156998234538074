import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
import Swal from 'sweetalert2'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        // const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (true) {
          // eslint-disable-next-line no-param-reassign
          config.withCredentials = true
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        console.log(response)
        return response
      },
      error => {
        const { config, response } = error
        console.log(error)
        const originalRequest = config



        if (response && response.data && response.data.exception != null) {
          console.log(response.data)
          Swal.fire({
            html: `
          <div style="text-align: start;margin-left:3px; margin-right: 3px; padding:5px">
          ${response.data.exception.replace('"', '').replace('frappe.exceptions.', '')}
          </div>
        `,
            icon: 'error',
            toast: true,
            position: 'top-end',
            width: 400,
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.onmouseenter = Swal.stopTimer
              toast.onmouseleave = Swal.resumeTimer
            },
          })
        }
        if (response && (response.status === 401 || response.status === 403)) {
          localStorage.removeItem('userData')
          var baseUrl = window.location.origin;
          var loginPageUrl =  "/portal#/login";
          window.location.href = loginPageUrl;


          // Reset ability
          this.$ability.update(initialAbility)
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  jobOpenings(...args) {
    return this.axiosIns.post(this.jwtConfig.jobOpenings, ...args)
  }

  myJobOpenings(...args) {
    return this.axiosIns.post(this.jwtConfig.myJobOpenings, ...args)
  }

  activateUser(...args) {
    return this.axiosIns.post(this.jwtConfig.activateUser, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  resetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPassword, ...args)
  }

  createJobApplicant(...args) {
    return this.axiosIns.post(this.jwtConfig.createJobApplicant, ...args)
  }

  applyJob(...args) {
    return this.axiosIns.post(this.jwtConfig.applyJob, ...args)
  }

  api(url, args) {
    const refinedArgs = this.stringifyNestedObjects(args)
    return this.axiosIns.post(`${this.jwtConfig.api}${url}`, refinedArgs)
  }

  direct_api(url, args) {
    return this.axiosIns.post(`${this.jwtConfig.api}${url}`, args)
  }

  changeUserPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.changeUserPassowrd, ...args)
  }

  stringifyNestedObjects(input) {
    if (typeof input === 'object' && input !== null) {
      for (const key in input) {
        if (typeof input[key] === 'object' && input[key] !== null) {
          input[key] = JSON.stringify(input[key])
        }
      }
    }
    return input
  }

  uploadFile(args, payload) {
    let formData = new FormData()
    formData = new FormData()
    formData.append('file', args.file)
    formData.append('docname', payload.docname)
    formData.append('doctype', payload.doctype)
    formData.append('is_private', 1)
    formData.append('folder', 'Home/Attachments')
    console.log('Payload')
    console.log(args)
    console.log(formData)
    console.log(formData.keys().length)
    return this.axiosIns.post(this.jwtConfig.uploadFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
