<template>
  <div>

    <b-row>
      <doctype-render
        v-if="selectedDoctype"
        style="width: 100%"
        :fields="[]"
        :doctype-input="selectedDoctype"
        :doc_ref="selectedReference"
      />
    </b-row>
    <b-table
      ref="selectableTable"
      v-model="actualTableData"
      small
      :items="actualTableData"
      :fields="fields"
      :select-mode="selectMode"
      responsive="sm"
      selectable
      bordered
      :stacked="false"
      :stacked-heading="doctype"
      striped
      @row-selected="onRowSelected"
    >
      <template #cell(_)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true" />
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true" />
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #cell(pos)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <div
          v-if="getDataType(data.field.key).fieldtype === 'Link'"
          style="width: 100%"
        >
          <b-input-group class="mb-2">
            <b-form-input
              v-model="data.value"
              type="text"

              class="table-input"
            />
            <b-input-group-prepend
              is-text
              @click="
                setCurrentDoctype(
                  getDataType(data.field.key).options,
                  data.value
                )
              "
            >
              <b-icon icon="link45deg" />
            </b-input-group-prepend>
          </b-input-group>
        </div>
        <div
          v-else-if="getDataType(data.field.key).fieldtype === 'Text Editor'"
          style="width: 100%"
        >
          <b-input-group
            v-if="!data.value"
            class="mb-2"
          >
            <b-form-input
              v-model="data.value"
              type="text"
            />
            <b-input-group-prepend
              is-text
              @click="
                setCurrentDoctype(
                  getDataType(data.field.key).options,
                  data.value
                )
              "
            >
              <b-icon icon="link45deg" />
            </b-input-group-prepend>
          </b-input-group>
          <span
            v-else
            v-html="data.value"
          />
        </div>
        <div
          v-else
          style="width: 100%"
        >
          <!-- <p>{{data}}</p> -->
          <b-form-input
            v-model="actualTableData[data.index][data.field.key]"
            :type="'text'"
            :readonly="readOnly"
          />
        </div>
      </template>
    </b-table>

    <b-row
      v-if="!readOnly"
      class="mt-2"
    >
      <b-col cols="12">
        <b-button
          variant="outline-secondary"
          class="pull-left mr-2"
          size="sm"
          @click="addItem()"
        >
          <b-icon
            icon="plus-circle-fill"
            aria-hidden="true"
          /> Add
        </b-button>
        <b-button
          variant="outline-danger"
          class="pull-left"
          size="sm"
          @click="removeItem()"
        >
          <b-icon
            icon="trash"
            aria-hidden="true"
          /> Remove
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  getDoctypeWithMetaSlim,
  getDoctypeWithMeta,
} from './forms_api'
import renderModule from '../render/module.js'

export default {
  name: 'ChildTable',
  props: {
    doctype: { type: String },
    readOnly: { type: Boolean, default: true },
    tableData: { type: Array, default: [] },
    fields: { type: Array, default: [] },
    doc: { type: String, default: '' },
    tableMeta: null,
    selectedDoctype: null,
    selectedReference: null,
    parentName: null,
  },
  data() {
    return {
      meta: {},
      actualTableData: [],
      inputFields: [],
      allFields: [],
      selectMode: 'multi',
      selected: [],
      allowFields: [],
    }
  },
  computed: {
    storedTableData() {
      return this.$store.getters['render/getTableData']
    },
  },
  watch: {
    actualTableData: {
      deep: true,
      handler(val) {
        if (!this.readOnly) {
          this.$emit('childUpdated', val)
        }
      },

    },
  },
  created() {
    this.$store.registerModule('render', renderModule)
    this.actualTableData = this.tableData
    this.loadMetaData()
  },
  mounted() {},
  methods: {
    addItem() {
      const fields = this.inputFields
      const me = this

      const d = new frappe.ui.Dialog({
        title: 'Create',
        fields,
        primary_action_label: 'Add',
        primary_action(values) {
          console.log(values)
          me.loadAddItemToTable(values)
          d.hide()
        },
      })

      d.show()
    },
    onRowSelected(items) {
      this.selected = items
    },
    loadAddItemToTable(values) {
      this.actualTableData.push(values)
      const val = this.actualTableData
      this.setTableData(val)
      this.$set(this, 'actualTableData', val)
      console.log(this.actualTableData)
    },
    removeItem() {
      this.actualTableData = this.actualTableData.filter(item => !(this.selected.filter(x => x == item).length > 0))
    },
    setTableData(data) {
      this.$store.dispatch('render/updateTable', data)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    loadMetaData() {
      const payload = { doctype: this.doctype }
      getDoctypeWithMetaSlim(payload).then(data => {
        this.meta = data.meta
        this.allFields = data.meta.fields
        if (data.allowed_fields && data.allowed_fields.allowed_fields) {
          this.allowedFields = data.allowed_fields.allowed_fields
        }
        let checkList = []
        if (this.allowedFields) {
          checkList = this.allowedFields
            .filter(x => x.active)
            .map(v => v.field_name)
        }

        const tempFields = this.allFields
          .filter(field => field.in_list_view === 1 || field.reqd === 1)
          .map(field => {
            field.key = field.fieldname
            field.read_only = 0
            return field
          })

        const indexList = []
        tempFields.forEach((field, index, arr) => {
          indexList.push({ pos: index + 1 })
        })

        this.fields = [...['_'], indexList, ...tempFields]

        this.inputFields = this.allFields.filter(
          field => field.in_list_view === 1 || field.reqd === 1,
        )

        if (this.checkList.length) {
          this.fields = this.fields.filter(
            item => checkList.indexOf(item.fieldname) > -1,
          )

          this.inputFields = this.inputFields.filter(
            item => checkList.indexOf(item.fieldname) > -1,
          )
        }
      })
    },
    updateRow(doctype, name) {
      const payload = { doctype, name }
      getDoctypeWithMeta(payload).then(data => {
        actualTableData.push(data.data)
      })
    },
    getDataType(key) {
      return (
        (this.fields || []).find(element => element.fieldname === key) || {}
      )
    },
    setCurrentDoctype(doctype, reference) {
      this.selectedDoctype = doctype
      this.selectedReference = reference
      this.$router.push({ name: 'viewer', params: { doctype, reference } })
      // this.$refs["my-modal-1993"].show();
    },
  },
}
</script>

<style scoped>
.ql-editor {
  background: white;
  border-radius: 9px;
  padding-top: 10px;
  padding-bottom: 10px;
}
input {
  background: transparent !important;
  border: 1px  solid grey;
  /* border-top: 0px;
  border-bottom: 0px;
  border-radius: 0px; */
}
</style>
