import state from './moduleServicePointState.js'
import mutations from './moduleServicePointMutations.js'
import actions from './moduleServicePointActions.js'
import getters from './moduleServicePointGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
