// procedureTemplates: [],
//     scheduledProcedures: [],
//     unScheduledProcedures: [],
export default {
  SET_PROCEDURE_TEMPLATES(state, templates) {
    state.procedureTemplates = templates
  },
  SET_UNSCHEDULED_PROCEDURES(state, unScheduledProcedures) {
    state.unScheduledProcedures = unScheduledProcedures
  },
  SET_SCHEDULED_PROCEDURES(state, scheduledTemplates) {
    state.scheduledProcedures = scheduledTemplates
  },
  SET_QUERY_PARAMS(state, query) {
    state.queryParams = { ...state.queryParams, ...query }
  },
  SET_PAGINATION_META(state, pagination) {
    state.pagination = pagination
  },
  SET_PROCEDURE_STATISTICS(state, procedureStatistics) {
    state.procedureStatistics = procedureStatistics
  },
}
