<template>
  <div class="custom-scroll-wrapper">
    <div class="custom-scroll-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomScrollWrapper',
}
</script>

  <style scoped>
  .custom-scroll-wrapper {
    /* overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    height: calc(85vh); */
  }

  .custom-scroll-content {
    /* You can add additional styling for the content if needed */
  }
  </style>
