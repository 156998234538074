const mutations = {
  UPDATE_USER_INFO(state, payload) {
    state.AppActiveUser = payload
  },
  UPDATE_TABLE_DATA(state, payload) {
    state.tableData = payload
  },
}

export default mutations
