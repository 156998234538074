<template>
  <div
    style="width: 100%"
    class="mt-1"
  >
    <br
      v-if="docField.fieldname.startsWith('column_break')"
      style="width: 100%"
    >

    <div
      v-else-if="docField.fieldtype === 'Link'"
      h-align="center"
      class="ml-1"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <b-icon
          icon="link45deg"
          font-scale="2"
        >
          <span
            v-if="docField.reqd"
            v-align="center"
            class="required"
          >*</span></b-icon></label>

      <doctype-link
        :id="`${docField.fieldname}`"
        class="ref-field-input w-100"
        :doctype="docField.options"
        :placeholder="`Search ${docField.options}`"
        @selected="change"
      />

    </div>

    <div
      v-else-if="docField.fieldtype === 'Dynamic Link'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <b-icon
          icon="link45deg"
          font-scale="2"
        >
          <span
            v-if="docField.reqd"
            v-align="center"
            class="required"
          >*</span></b-icon></label>
      <doctype-link
        :id="`${docField.fieldname}`"
        class="ref-field-input w-100"
        :doctype="docField.options"
        :placeholder="`Search ${docField.option}`"
        @selected="change"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Table'"
      style="width: 100%"
    >

      <child-table
        style="width: 100%"
        :table-data="doc[docField.fieldname]"
        :read-only="readOnly"
        :doc="docField.fieldname"
        :doctype="docField.options"
        @childUpdated="childUpdated"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Text'"
      style="width: 100%"
      class="mb-2"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span></label>
      <b-form-textarea
        v-model="doc[docField.fieldname]"
        rows="10"
        :type="'text'"
        max-rows="50"
        :readonly="readOnly"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Select'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span></label>
      <b-form-select
        v-model="doc[docField.fieldname]"
        :readonly="readOnly"
        :options="processOptions(docField.options)"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Float'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span></label>
      <b-form-input
        v-model="doc[docField.fieldname]"
        :type="'number'"
        :readonly="readOnly"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Currency'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span></label>
      <b-form-input
        v-model="doc[docField.fieldname]"
        :type="'number'"
        :readonly="readOnly"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Int'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span>
      </label>
      <b-form-input
        v-model="doc[docField.fieldname]"
        :type="'number'"
        :readonly="readOnly"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Button'"
      style="width: 100%"
    >
      <b-button
        v-align="center"
        variant="light"
      >
        {{ docField.label }}</b-button>
    </div>

    <div
      v-else-if="docField.fieldtype === 'Check'"
      style="width: 100%"
    >
      <b-form-checkbox
        v-model="doc[docField.fieldname]"
        class="pt-4"
        :readonly="readOnly"
        value="1"
        unchecked-value="0"
        variant="primary"
      >
        {{ docField.label }}
      </b-form-checkbox>
    </div>

    <div
      v-else-if="docField.fieldtype === 'Text Editor'"
      style="width: 100%"
      class="mb-2"
    >
      <label
        for="input-live"
      >{{ docField.label
      }}<span
        v-if="docField.reqd"
        v-align="center"
        class="required"
      >*</span></label>

      <b-form-textarea
        v-model="doc[docField.fieldname]"
        rows="10"
        :type="'text'"
        max-rows="50"
        :readonly="readOnly"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Date'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label
      }}<span
        v-if="docField.reqd"
        v-align="center"
        class="required"
      >*</span></label>
      <b-form-datepicker
        v-model="doc[docField.fieldname]"
        :readonly="readOnly"
        class="mb-2"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Time'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label
      }}<span
        v-if="docField.reqd"
        v-align="center"
        class="required"
      >*</span></label>
      <b-form-timepicker
        v-model="doc[docField.fieldname]"
        :readonly="readOnly"
        class="mb-2"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Datetime'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label
      }}<span
        v-if="docField.reqd"
        v-align="center"
        class="required"
      >*</span></label>
      <b-form-datepicker
        v-model="doc[docField.fieldname]"
        :readonly="readOnly"
        class="mb-2"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Image'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label
      }}<span
        v-if="docField.reqd"
        v-align="center"
        class="required"
      >*</span></label>
      <img style="width: 10px; height: 10px">
    </div>

    <div
      v-else-if="docField.fieldtype === 'Data'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span></label>
      <b-form-input
        v-model="doc[docField.fieldname]"
        :type="'text'"
        :readonly="readOnly"
      />
    </div>

    <div
      v-else-if="docField.fieldtype === 'Attach Image'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span></label>
      <b-button
        size="sm"
        class="mb-2 mt-2"
        @click="uploadFile"
      >
        <b-icon
          icon="paperclip"
          aria-hidden="true"
        /> Attach Image
      </b-button>
    </div>

    <div
      v-else-if="docField.fieldtype === 'Small Text'"
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label }}
        <span
          v-if="docField.reqd"
          v-align="center"
          class="required"
        >*</span></label>
      <br>
      <b-form-input
        v-model="doc[docField.fieldname]"
        :type="'text'"
        :readonly="readOnly"
      />
    </div>

    <div v-else-if="docField.fieldtype === 'HTML'">
      <span v-html="doc[docField.fieldname]" />
    </div>

    <div
      v-else
      style="width: 100%"
    >
      <label
        for="input-live"
      >{{ docField.label
      }}<span
        v-if="docField.reqd"
        v-align="center"
        class="required"
      >*</span></label>
      {{ docField.fieldtype }}
    </div>
  </div>
</template>

<script>
import DocTypeLink from '@/views/components/doctype-link/DoctypeLink.vue'
import ChildTable from './ChildTable.vue'
import { searchDoctype } from './forms_api'

export default {
  name: 'DocField',
  components: { ChildTable, DocTypeLink },
  props: {
    docField: { type: Object, default: {} },
    doc: { type: Object, default: {} },
    readOnly: { type: Boolean, default: true },
  },
  data() {
    return {
      selected: null,
      items: [],
      loading: false,
      timeoutId: null,
      noData: false,
    }
  },
  watch: {
    doc: {
      handler(val1, val2) {
        if (this.docField.fieldtype === 'Dynamic Link') {
          // this.makeDynamicDoctypeControl();
          // if (val1[this.docField.options] !== val2[this.docField.options]) {

          // }
        }
      },
      deep: true,
    },
  },
  mounted() {
    // if (this.docField.fieldtype === "Link") {
    //   this.makeSelectDoctypeControl();
    // } else if (this.docField.fieldtype === "Dynamic Link") {
    //   this.makeDynamicDoctypeControl();
    // }
  },
  methods: {
    removeUnwanted(items) {
      if (!items || items.length === 0) {
        return []
      }
      const unWanted = [
        'name',
        'owner',
        'creation',
        'modified',
        'parent',
        'parentfield',
        'modified_by',
        'parenttype',
        'doctype',
        'docstatus',
        'idx',
      ]
      const result = items.map(item => {
        unWanted.forEach(key => {
          delete item[key]
        })

        return item
      })

      return result
    },
    onSearch(search) {
      const lettersLimit = 2
      this.noData = false
      if (search.length < lettersLimit) {
        this.items = []
        this.loading = false
        return
      }
      this.loading = true

      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(async () => {
        const args = {
          txt: search,
          doctype: this.docField.dt,
          reference_doctype: '',
        }
        searchDoctype(args).then(result => {
          this.items = result.map(item => item.value)
          this.loading = false
          if (!this.items.length) {
            this.noData = true
          }

          console.log(this.items)
        })
      }, 500)
    },
    change(val) {
      const data = this.docField.fieldname
      this.$set(this.doc, data, val)
    },
    makeSelectDoctypeControl() {
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Reference'),
          fieldtype: 'Link',
          fieldname: 'reference',
          options: me.docField.options,
          placeholder: (''),
          onchange() {
            if (this.value) {
              me.change(this.value)
            }
          },
        },
        parent: this.$refs.doctype,
        render_input: true,
      })

      customer_field.toggle_label(false)
      customer_field.$input.val(me.doc[me.docField.fieldname])
      $('#modal-body').find('.input-max-width').removeClass('input-max-width')
    },
    makeDynamicDoctypeControl() {
      $(`#${this.docField.fieldname}`).html('')
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Reference'),
          fieldtype: 'Link',
          fieldname: 'reference',
          options: me.doc[me.docField.options],
          placeholder: (''),
          onchange() {
            if (this.value) {
              me.doc[me.docField.fieldname] = this.value
            }
          },
        },
        parent: this.$refs.doctype,
        render_input: true,
      })

      customer_field.toggle_label(false)
      customer_field.$input.val(me.doc[me.docField.fieldname])
      $('#modal-body').find('.input-max-width').removeClass('input-max-width')
    },
    uploadFile() {
      const me = this
      new frappe.ui.FileUploader({
        doctype: 'Internal Memo',
        docname: me.parentId,
        on_success(file_doc) {
          const { file_url, filename } = file_doc
          me.doc[me.docField.fieldname] = file_url
        },
      })
    },
    setCurrentDoctype(doctype, reference) {
      this.selectedDoctype = doctype
      this.selectedReference = reference
      if (reference && doctype) {
        this.$router.push({ name: 'viewer', params: { doctype, reference } })
      }

      // this.$refs["my-modal-1993"].show();
    },
    processOptions(options) {
      if (!options) {
        return []
      }
      const optionList = options.split('\n')
      return optionList
    },
    childUpdated(val) {
      this.$emit('childUpdated', { val, fieldname: this.docField.fieldname })
    },
  },
}
</script>

<style scoped>
.required {
  color: red;
  font-size: 14px;
}
</style>
