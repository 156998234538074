import {
  getPatient, getAccountsDashboard,
} from './service'

export default {
  fetchPatient({ commit }, patientName) {
    getPatient({ patient_name: patientName }).then(result => {
      if (result) {
        commit('SET_PATIENT', result)
      }
    })
  },

  fetchPatientAccountSummary({ commit }, patientName) {
    getAccountsDashboard({ patient_number: patientName }).then(result => {
      if (result) {
        commit('SET_PATIENT_ACCOUNT_SUMMARY', result)
      }
    })
  },

}
