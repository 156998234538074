import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPatientsPerServiceUnit = ({
  serviceUnit: service_unit,
  servicePoint: service_point,
  search = '',
  traverse = '',
  passedDate: passed_date = '',
}) => api({
  method: 'clinical.api.patients.get_patients_per_service_unit',
  args: {
    service_unit,
    service_point,
    search,
    traverse,
    passed_date,
  },
  freeze: false,
})

export const transferPatient = args => api({
  method: 'clinical.api.patients.transfer_inpatient',
  args,
})

export const getPatientInfo = patient_name => api({
  method: 'clinical.api.patients.get_patient_details',
  args: patient_name,
})
