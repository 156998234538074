export default {
  SET_PATIENT_SEARCH_QUERY(state, query) {
    state.patientSearchQuery = query
  },
  UPDATE_PATIENT_FILTER(state, filter) {
    state.patientFilter = filter
  },
  SET_PATIENTS(state, patients) {
    state.patients = patients
  },
  ADD_PATIENT(state, patient) {
    state.patients.unshift(patient)
  },
  UPDATE_PATIENT(state, patient) {
    const patientIndex = state.patients.findIndex(t => t.id == patient.id)
    Object.assign(state.patients[patientIndex], patient)
  },
  SET_SELECTED_PATIENT(state, patient) {
    state.selectedPatient = patient
  },
  SET_SELECTED_PATIENT_DETAILS(state, patientDetails) {
    state.selectedPatientDetails = patientDetails
  },
  SET_TIMELINE_DATA(state, timelineData) {
    state.timelineData = timelineData
  },

}
