<template>
  <div style="width:100% !important">
    <div rounded="sm">

      <b-container
        class="mb-4"
        style="width:100% !important"
      >
        <div class="spacer-left-5">
          <b-row
            align-h="end"
            class="render-form"
          >
            <b-row
              v-if="(formData && !isReadonly) || prePopulate != null"
              align-h="end"
              class=""
              style="width:100%"
            >
              <!-- <b-col cols="8">
                  <h3 class="ml-2 upper-case">{{ currentFormName }}</h3>
                </b-col> -->
              <b-button
                v-if="draftForm.name"
                size="sm"
                id="t0"
                variant="primary"
                class="white-text mr-1"
                @click="saveDoctype()"
              >
              <i class="fa fa-circle-check"></i> Save Changes
              </b-button>

              <b-button
                v-if="(!draftForm.name && formData && !isReadonly) ||
                  (!draftForm.name && isReadonly && isSaveOnly) || (prePopulate != null && !draftForm.name)
                "
                variant="primary"
                id="yui"
                class="white-text pull-right mr-1"
                size="sm"
                @click="showModal()"
              >
              <i class="fa-solid fa-lock"></i> Click to Open
              </b-button>
            </b-row>

            <b-overlay
              style="width:100% !important"
              :show="(isShowOverlay && !isReadonly) || isReadonly"
              :opacity="opacity"
              no-center
              rounded="sm"
            >

              <FormRenderer
                v-if="renderComponent"
                v-model="formInputData"
                style="width:100% !important;  overflow-y: scroll;"
                :form-configuration="formData"
                :parent="draftForm.name || parent"
                :reference="reference"
              />
              <template #overlay>
                <b-row align-h="end">
                  <b-button
                    v-if="isSaveOnly && draftForm.name && isReadonly"
                    variant="primary"
                    size="sm"
                    class="white-text pull-right mr-5 mt-3"
                    @click="saveDoctype()"
                  >
                    <i class="fa fa-save" /> Save
                  </b-button>
                </b-row>
              </template>
            </b-overlay>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="pull-right"
            >
              <b-button-group
                class="pull-right"
                style="width: 100% !important;"
              >
                <b-button
                  v-if="draftForm.name"
                  style="width: 100% !important;"
                  variant="danger"
                  class="white-text pull-right"
                  @click="saveDoctype()"
                >
                  <i class="fa fa-save" /> SAVE THIS FORM
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>

          <b-modal
            id="modal-id-1"
            ref="save_modal"
            size="sm"
            hide-footer
          >
            <template #modal-title>
              Confirm save form data
            </template>
            <b-row
              id="modal-body"
              class="modal-padding"
            >

              <b-col
                ref="doctype"
                :cols="12"
                class="ref-field-input"
              />

              <b-col
                v-show="selectedDoctype"
                id="ref-field"
                ref="docId"
                :cols="12"
                class="ref-field-input"
              />

              <b-col
                id="form"
                :cols="12"
                class="ref-field-input"
              />
            </b-row>

            <b-button
              class="mt-3 btn btn-primary"
              block
              @click="initialSave()"
            >
              Save
            </b-button>
          </b-modal>

        </div>
      </b-container>

    </div>
  </div>
</template>
<script>
import { FormRenderer } from 'vue-frappe-formbuilder-health'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FormRenderView',
  components: {},
  props: {
    selectedDoctype: { type: String },
    selectedDoctypeReference: { type: String },
    currentFormName: { type: String },
    patient: { type: Object },
    dataInput: { type: Object },
    prePopulate: { type: Object },
    isReadonly: { type: Boolean, default: false },
    isSaveOnly: { type: Boolean, default: false },
    hasOwner: { type: Boolean, default: false },
    powerThrough: { type: Boolean, default: false },
    parent: String,
  },

  data() {
    return {
      some_data: 'To',
      date: null,
      previewMode: false,
      title: '',
      department: '',
      tableName: '',
      configuration: {},
      draftForm: {},
      formData: null,
      formName: null,
      selectedItem: null,
      formInputData: null,
      originalConfig: null,
      changeLog: [],
      savedDocument: null,
      allFormConfigurationData: null,
      renderComponent: true,
      reference: {},
    }
  },
  computed: {
    context() {
      return this.$store.getters['context/getContext']
    },
    isShowOverlay() {
      return !this.draftForm.name
    },
    opacity() {
      return this.isReadonly ? 0 : 0.2
    },
    encounter() {
      return this.$store.getters['encounter/getEncounter']
    },
  },
  watch: {
    prePopulate(val) {
      this.dataPrepoulate(this.formData)
    },
    dataInput(newVal) {
      this.setValues(newVal)
    },

    previewMode(newVal, oldVal) {
      if (newVal) {
        const conf = this.configuration
        keys = Object.keys(newVal)
        keys.forEach(key => {
          this.formInputData[`${key}`] = newVal[`${key}`]
        })

        this.formData = conf
      }
    },
    selectedDoctype(doctype) {
      if (doctype) {
        this.makeDoctypeItemControl(doctype)
      }
    },
    currentFormName(name) {
      if (name) {
        this.getForm(name)
      }
    },

  },
  created() {
    if (this.currentFormName) {
      this.getForm(this.currentFormName)
    }
    this.populateChildTableReference()
  },

  mounted() {
    this.$formEvent.$on('submit', value => { })
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'modal-id-1') {
        this.makeSelectDoctypeControl()
      }
    })
    const context = this
    setTimeout(() => {
      context.populate()
    }, 1000)
  },
  methods: {
    showToast(message, time) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: message,
        },
      })
    },
    forceRerender() {
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    populate() {
      this.setValues(this.dataInput)
    },
    dataPrepoulate(configuration) {
      if (this.prePopulate) {
        const keys = Object.keys(this.prePopulate)
        const controlValues = Object.values(configuration.controls)
        const transformedData = {}
        keys.forEach(key => {
          const currentControl = controlValues.find(
            control => control.mappedField === key,
          )
          if (currentControl) {
            const updatedKey = currentControl.name
            transformedData[updatedKey] = this.prePopulate[key]
          }
        })

        this.dataInput = transformedData
        this.draftForm = { name: this.prePopulate.name }
      }
    },
    clearData() {
      const val = {}
      const keys = Object.keys(this.formInputData)
      keys.forEach(key => {
        val[key] = ''
      })

      this.setValues(val)
    },

    setValues(val) {
      this.$set(this, 'formInputData', val)
    },
    getFormData() {
      if (!this.currentFormName) {
        const formName = frappe.get_route()[2]
        this.tableName = formName.split('-')[1]
        this.showModal()
      } else if (!this.selectedDoctype || !this.selectedDoctypeReference) {
        this.showModal()
      } else {
        this.save()
      }
    },
    getFormInputData() { },
    exportData() {
      this.showModal()
    },
    previewForm() {
      this.previewMode = !this.previewMode
    },
    showModal() {
      if (this.hasOwner) {
        this.$refs.save_modal.show()
      } else if (!this.draftForm.name) {
        this.initialSave()
      } else {
        this.save()
      }
      this.$emit('encounterCallback', this.selectedDoctypeReference)
    },
    clear() {
      this.formData = null
      this.formData = this.originalConfig
    },
    makeSelectDoctypeControl() {
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Reference'),
          fieldtype: 'Link',
          fieldname: 'reference',
          options: 'DocType',
          placeholder: ('Search Reference'),
          onchange() {
            if (this.value) {
              me.selectedDoctype = this.value
            }
          },
        },
        parent: this.$refs.doctype,
        render_input: true,
      })

      customer_field.toggle_label(false)
      $('#modal-body')
        .find('.input-max-width')
        .removeClass('input-max-width')
    },
    get_new_frm(_frm) {
      const doctype = 'Patient'
      const page = $('#form')

      const layout = new frappe.ui.form.Layout({
        parent: page,
        doctype,
        doctype_layout: null,
        frm: {},
        with_dashboard: false,
        card_layout: true,
      })
      layout.make()
      console.log(layout)

      return frm
    },
    makeDoctypeItemControl(doctype) {
      const div = $('#ref-field')
      div.empty()
      const me = this
      const customer_field = frappe.ui.form.make_control({
        df: {
          label: ('Reference Id'),
          fieldtype: 'Link',
          fieldname: 'itemControl',
          options: doctype,
          placeholder: ('Reference Id'),
          onchange() {
            if (this.value) {
              me.selectedDoctypeReference = this.value
            }
          },
        },
        parent: this.$refs.docId,
        render_input: true,
      })
      customer_field.toggle_label(false)
      $('#modal-body')
        .find('.input-max-width')
        .removeClass('input-max-width')
    },
    saveForm(formData) {
      this.hideModal()
      useJwt.api('clinical.api.forms.form_builder.save_form_data', { form_data: formData }).then(saved => {
        // this.showToast("Form Saved " + saved.name, 5);
        this.formData = null
        this.setValues({})
        this.selectedDoctype = null
        this.selectedDoctypeReference = null
        this.sendToTimeline(
          this.patient.patient,
          'Form Repository',
          saved.name,
          saved.owner,
        )
      })
    },
    initialSave() {
      this.hideModal()
      const form_content = '{}'
      const form_name = this.formName
      const reference_doctype = this.selectedDoctype
      const reference_doctype_id = this.selectedDoctypeReference
      const doctype = 'Form Repository'
      const formData = {
        doctype,
        form_content,
        form_name,
        reference_doctype,
        reference_doctype_id,
        completion_status: 'Initial',
      }
      useJwt.api('clinical.api.forms.form_builder.save_form_data', { form_data: formData }).then(saved => {
        saved = saved.data.message
        this.draftForm = saved
        if (this.powerThrough) {
          this.saveDoctype()
        }
      })
    },
    getForm(name) {
      useJwt.api('clinical.api.forms.form_builder.get_form_configuration', { name }).then(config => {
        config = config.data.message
        this.allFormConfigurationData = config
        const formStringConfig = config.formdata
        const configObject = JSON.parse(formStringConfig)
        this.dataPrepoulate(configObject)
        this.formName = config.name
        this.formData = configObject
        this.originalConfig = configObject
      })
    },

    hideModal() {
      this.$refs.save_modal.hide()
    },
    navigateToList() {
      const formName = frappe.get_route()[2]
      this.tableName = formName.split('-')[1]
    },

    sendToTimeline(
      patient,
      reference_doctype,
      reference_name,
      reference_owner,
    ) {
      if (patient == 'Form Repository') {
        this.showToast('Registration Completed', 5)
        return
      }

      const formData = {
        reference_doctype,
        reference_name,
        doctype: 'Patient Medical Record',
        patient,
        status: 'Open',
        reference_owner,
      }

      useJwt.api('clinical.api.forms.form_builder.save_form_data', { form_data: formData }).then(saved => {
        this.showToast('Timeline updated', 5)
      })
    },
    saveDoctype() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to save the changes?')
        .then(value => {
          if (value) {
            this.saveDoctypeToDb()
          }
          this.$bvModal.hide('add_l_modal')
        })
        .catch(err => {
          // An error occurred
        })
    },
    saveDoctypeToDb() {
      console.log('FRM', 'saving Doctype')
      if (this.formData.formConfig.mappedDoctype) {
        console.log('FRM', 'mapped doctype')
        const keys = Object.keys(this.formInputData)
        const formData = { doctype: this.formData.formConfig.mappedDoctype }

        let isValid = true

        // check validations
        Object.keys(this.formData.controls).forEach(key => {
          if (this.formData.controls[key] != null && this.formData.controls[key].validations != null && isValid) {
            this.formData.controls[key].validations.forEach(validation => {
              if (validation.ruleType == 'required' && this.formInputData[key] == null) {
                this.showToast(`Field ${this.formData.controls[key].label} is required`)
                isValid = false
              }

              if (validation.ruleType == 'min' && `${this.formInputData[key]}`.length < validation.additionalValue) {
                this.showToast(`Field ${this.formData.controls[key].label} is need at least ${validation.additionalValue} characters`)
                isValid = false
              }

              if (validation.ruleType == 'max' && `${this.formInputData[key]}`.length > validation.additionalValue) {
                this.showToast(`Field ${this.formData.controls[key].label} can only have a max of ${validation.additionalValue} characters`)
                isValid = false
              }
            })
          }
        })

        if (!isValid) {
          return
        }

        keys.forEach(key => {
          console.log(key)
          if (this.formData.controls[key]) {
            const control = this.formData.controls[key]
            if (
              control
              && (control.type === 'radio'
                || control.type === 'dropDown'
                || control.type === 'checkbox')
            ) {
              if (control.items.length) {
                const erpValueObject = control.items.find(
                  item => item.value === this.formInputData[key],
                )
                const field = this.formData.controls[key].mappedField
                if (field) {
                  formData[field] = erpValueObject.erpValue
                }
              }
            } else {
              const field = this.formData.controls[key].mappedField
              if (field) {
                formData[field] = this.formInputData[key]
              }
            }
          } else if (Array.isArray(this.formInputData[key])) {
            formData[key] = this.formInputData[key]
          }

          this.allFormConfigurationData.context_item.forEach(item => {
            formData[item.key] = this.context[item.value]
          })

          if (this.allFormConfigurationData.extras && this.allFormConfigurationData.extras.length) {
            this.allFormConfigurationData.extras.forEach(item => {
              formData[item.key] = item.value
            })
          }
        })

        console.log('FRM', 'saving form')
        useJwt.api('clinical.api.forms.form_builder.save_form_data', { form_data: formData }).then(saved => {
          saved = saved.data.message
          // this.showToast("Saved", 3);
          this.savedDocument = saved
          this.getFormData()
        })
      } else {
        console.log('FRM', 'getFormData')
        this.getFormData()
      }
    },
    populateChildTableReference() {
      this.reference = { doctype: this.selectedDoctype, doctype_id: this.selectedDoctypeReference }
    },
    save() {
      this.hideModal()
      let form_content = this.formInputData
      form_content = JSON.stringify(form_content)
      const form_name = this.formName
      const reference_doctype = this.allFormConfigurationData.owner_doctype
        || this.selectedDoctype
        || this.formData.formConfig.mappedDoctype
      let reference_doctype_id = null
      if (this.allFormConfigurationData.context_reference) {
        reference_doctype_id = this.context[this.allFormConfigurationData.context_owner_name]
          || this.selectedDoctypeReference
          || this.savedDocument.name
      } else {
        reference_doctype_id = this.allFormConfigurationData.owner_doctype_reference
          || this.selectedDoctypeReference
          || this.savedDocument.name
      }

      let name = null

      if (this.draftForm && this.draftForm.name) {
        name = this.draftForm.name
      }
      const doctype = 'Form Repository'
      const formData = {
        doctype,
        name,
        form_content,
        form_name,
        reference_doctype,
        reference_doctype_id,
        completion_status: 'Completed',
        completed: 1,
      }

      if (this.encounter && this.encounter.name) {
        formData.patient_encounter = this.encounter.name
      }

      useJwt.api('clinical.api.forms.form_builder.update_form_data', { form_data: formData }).then(result => {
        result = result.data.message
        // this.showToast("Form updated " + result.name, 5);
        this.sendToTimeline(
          reference_doctype_id,
          'Form Repository',
          result.name,
          result.owner,
          result.form_name,
        )
        this.draftForm = {}
        this.clearData()
        this.forceRerender()
        this.$emit('callback', { formData, document: this.savedDocument })
      })
    },
  },
}
</script>
<style scoped>
.spacer {
  margin-top: 10px;
}

.space-right {
  margin-right: 10px !important;
  padding-right: 10px;
}

.spacer-left {
  margin-left: 10px !important;
  padding-left: 10px;
}

.save-btn {
  color: white;
  margin-top: 2%;
}

.spacer-left-5 {
  margin-left: -24px !important;
  padding-left: 5px;
}

.render-form {
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 50px;
  width: 100% !important;
}

.white-text {
  color: white;
  margin-left: 10px;
}

.form-border {
  border: 1px solid darkgray;

  margin-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 50px;
}

.main-padding {
  padding-bottom: 50px;
  /* padding-right: 50px; */
}

.main-page {
  margin-top: 0px;
  padding-right: 30px;
}

.top-margin {
  margin-top: 20px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.fb-area {
  padding-top: 20px;
  border-radius: 10px;
}

.container-fluid {
  background-color: white;
  border-radius: 10px;
}

.space-left {
  margin-left: 0px;
}

.ref-field-input {
  width: 160px;
  padding-left: 0px;
}

.input-max-width {
  width: 100% !important;
  min-width: 300px;
}

.modal-padding {
  padding-left: 15px;
}

.upper-case {
  text-transform: uppercase;
}

svg {
  width: 14px !important;
}
</style>
