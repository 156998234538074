export default {

  SET_SUPPLIERS(state, suppliers) {
    state.suppliers = suppliers
  },
  ADD_SUPPLIER(state, supplier) {
    state.suppliers.unshift(supplier)
  },
  UPDATE_SUPPLIER(state, supplier) {
    const supplierIndex = state.suppliers.findIndex(t => t.id == supplier.id)
    Object.assign(state.suppliers[supplierIndex], supplier)
  },
  SET_SELECTED_SUPPLIER(state, supplier) {
    state.selectedSupplier = supplier
  },
  SET_SELECTED_SUPPLIER_DETAILS(state, supplierDetails) {
    state.selectedSupplierDetails = supplierDetails
  },

}
