export default [
  {
    path: '/records/register-patient',
    name: 'register-patient',
    component: () => import('@/views/erp/records/patient/RegisterPatient.vue'),
  },
  {
    path: '/records/patient-referrals',
    name: 'patient-referrals',
    component: () => import('@/views/erp/records/patient/PatientReferrals.vue'),
  },

  {
    path: '/records/book-appointment/:patient',
    name: 'book-appointment-patient',
    component: () => import('@/views/erp/records/appointment/BookAppointment.vue'),
  },

  {
    path: '/records/appointment/:id',
    name: 'view-appointment',
    component: () => import('@/views/erp/records/appointment/components/AppointmentDetails.vue'),
  },
  {
    path: '/records/book-appointment',
    name: 'book-appointment',
    component: () => import('@/views/erp/records/appointment/BookAppointment.vue'),
  },

  {
    path: '/records/form-managment/form-list',
    name: 'form-list',
    component: () => import('@/views/erp/records/forms/FormsList.vue'),
  },
  {
    path: '/records/patient-details/:id',
    name: 'patient-view-actions',
    component: () => import('@/views/erp/records/patient/PatientDetails.vue'),
  },
  {
    path: '/records/form-managment/build-form',
    name: 'build-form',
    component: () => import('@/views/erp/records/forms/BuildForm.vue'),
  },

  {
    path: '/records/form-managment/edit-form/:id',
    name: 'edit-form',
    component: () => import('@/views/erp/records/forms/BuildForm.vue'),
  },
  {
    path: '/records/appointment-list',
    name: 'appointment-list',
    component: () => import('@/views/erp/records/appointment/AppointmentList.vue'),
  },

  {
    path: '/clinical/patient-file',
    name: 'patient-file',
    component: () => import('@/views/erp/clinical/PatientFile.vue'),
  },
  {
    path: '/clinical/clinical-procedures',
    name: 'procedures',
    component: () => import('@/views/erp/clinical/procedures/ClinicalProcedures.vue'),
  },
  {
    path: '/billing/patient-accounts',
    name: 'patient-accounts',
    component: () => import('@/views/erp/billing/PatientAccounts.vue'),
  },
  {
    path: '/billing/patient-accounts/:id',
    name: 'patient-account',
    component: () => import('@/views/erp/billing/PatientAccounts.vue'),
  },
  {
    path: '/clinical/pharmacy',
    name: 'pharmacy',
    component: () => import('@/views/erp/pharmacy/Pharmacy.vue'),
  },
  {
    path: '/reports/moh-706',
    name: 'moh-706',
    component: () => import('@/views/erp/reports/MOH706.vue'),
  },
  {
    path: '/reports/moh-705',
    name: 'moh-705',
    component: () => import('@/views/erp/reports/MOH705A.vue'),
  },

  {
    path: '/profile/my-accounts',
    name: 'my-accounts',
    component: () => import('@/views/erp/profile/UserProfiles.vue'),
  },
  {
    path: '/career',
    name: 'home-career-jobs',
    component: () => import('@/views/erp/jobs/LandingPage.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/records/patient-list',
    name: 'patient-list',
    component: () => import('@/views/erp/records/PatientList.vue'),
  },
  {
    path: '/career/account-setting',
    name: 'career-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'User',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/career/my-application/:id',
    name: 'career-my-application',
    component: () => import('@/views/erp/my-applications/MyApplication.vue'),
    meta: {
      pageTitle: 'Job Details',
      breadcrumb: [
        {
          text: 'career',
        },
        {
          text: 'position description',
          active: true,
        },
      ],
    },
  },
  {
    path: '/career/form-wizard',
    name: 'career-profile',
    component: () => import('@/views/erp/profile/ApplicantProfile.vue'),
    meta: {
      pageTitle: 'Applicant Details',
      breadcrumb: [
        {
          text: 'Profile',
        },
        {
          text: 'Applicant Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/career/activate-account',
    name: 'career-activate-account',
    component: () => import('@/views/erp/profile/activate/ActivateUser.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
]
