import state from './moduleServiceUnitState.js'
import mutations from './moduleServiceUnitMutations.js'
import actions from './moduleServiceUnitActions.js'
import getters from './moduleServiceUnitGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
