var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[(_vm.selectedDoctype)?_c('doctype-render',{staticStyle:{"width":"100%"},attrs:{"fields":[],"doctype-input":_vm.selectedDoctype,"doc_ref":_vm.selectedReference}}):_vm._e()],1),_c('b-table',{ref:"selectableTable",attrs:{"small":"","items":_vm.actualTableData,"fields":_vm.fields,"select-mode":_vm.selectMode,"responsive":"sm","selectable":"","bordered":"","stacked":false,"stacked-heading":_vm.doctype,"striped":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(_)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}},{key:"cell(pos)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell()",fn:function(data){return [(_vm.getDataType(data.field.key).fieldtype === 'Link')?_c('div',{staticStyle:{"width":"100%"}},[_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',{staticClass:"table-input",attrs:{"type":"text"},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}}),_c('b-input-group-prepend',{attrs:{"is-text":""},on:{"click":function($event){_vm.setCurrentDoctype(
                _vm.getDataType(data.field.key).options,
                data.value
              )}}},[_c('b-icon',{attrs:{"icon":"link45deg"}})],1)],1)],1):(_vm.getDataType(data.field.key).fieldtype === 'Text Editor')?_c('div',{staticStyle:{"width":"100%"}},[(!data.value)?_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}}),_c('b-input-group-prepend',{attrs:{"is-text":""},on:{"click":function($event){_vm.setCurrentDoctype(
                _vm.getDataType(data.field.key).options,
                data.value
              )}}},[_c('b-icon',{attrs:{"icon":"link45deg"}})],1)],1):_c('span',{domProps:{"innerHTML":_vm._s(data.value)}})],1):_c('div',{staticStyle:{"width":"100%"}},[_c('b-form-input',{attrs:{"type":'text',"readonly":_vm.readOnly},model:{value:(_vm.actualTableData[data.index][data.field.key]),callback:function ($$v) {_vm.$set(_vm.actualTableData[data.index], data.field.key, $$v)},expression:"actualTableData[data.index][data.field.key]"}})],1)]}}]),model:{value:(_vm.actualTableData),callback:function ($$v) {_vm.actualTableData=$$v},expression:"actualTableData"}}),(!_vm.readOnly)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"pull-left mr-2",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.addItem()}}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill","aria-hidden":"true"}}),_vm._v(" Add ")],1),_c('b-button',{staticClass:"pull-left",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeItem()}}},[_c('b-icon',{attrs:{"icon":"trash","aria-hidden":"true"}}),_vm._v(" Remove ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }