export default {
  SET_PATIENT(state, pat) {
    state.patient = pat
  },
  SET_PATIENT_ACCOUNT_SUMMARY(state, summary) {
    state.accountSummary = summary
  },
  SET_PATIENT_SALES_ORDER_TOTALS(state, total) {
    state.salesOrderTotal = total
  },
  SET_INVOICE_FINALIZATION(state, finalizations) {
    state.invoiceFInalization = finalizations
  },
}
