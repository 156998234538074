import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const apiPlain = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data))
  .catch(error => reject(error)))

export const apix = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.results))
  .catch(error => reject(error)))

export const getDoctypeWithMeta = payload => api({
  method: 'clinical.api.doctype.meta.get_doctype_with_meta',
  args: { payload },
})

export const getDoctypeWithMetaSlim = payload => api({
  method: 'clinical.api.doctype.meta.get_doctype_with_meta_slim',
  args: { payload },
})

export const getDoctypeRenderSettings = payload => api({
  method: 'mtrh_dev.mtrh_dev.doctype.renderer_settings.renderer_settings.get_render_settings',
  args: { payload },
})

export const updateDoctype = payload => api({
  method: 'frappe.desk.form.save.savedocs',
  args: { doc: payload, action: 'Update' },
})

export const emergency = appointment => api({
  method: 'billing.billing.api.patient.patient_balance.mark_emergency',
  args: { appointment },
})

export const creditedTurnUp = appointment => api({
  method: 'billing.billing.api.patient.patient_balance.credited_turn_up',
  args: { appointment },
})

export const rescheduleSingleAppointment = ({ appointment, date, time }) => api({
  method: 'clinical.api.appointment.appointment.reschedule_single_appointment',
  args: { appointment, date, time },
})

export const addPatientToQueue = appointment => api({
  method: 'billing.billing.api.patient.patient_balance.add_emergency_patient_to_queue',
  args: { appointment },
})

export const admitEmergencyPatient = (medical_department, appointment, bed) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.admit_emergency_patient',
  args: { medical_department, appointment, bed },
})

export const cancelDoctype = (doctype, name) => api({
  method: 'frappe.desk.form.save.cancel',
  args: { doctype, name },
})

export const getDoctype = (doctype, name) => apiPlain({
  method: 'frappe.desk.form.load.getdoc',
  args: { doctype, name },
})

export const searchDoctype = payload => apix({
  method: 'frappe.desk.search.search_link',
  args: { payload },
})

export const getList = payload => api({
  method: 'mtrh_dev.api.supplier-portal.base.base.get_list',
  args: { payload },
})
