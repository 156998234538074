import { getMyServicePoints, getMyLocation } from './service'

export default {
  setSelectedServicePoint({ commit }, servicePoint) {
    commit('SET_SELECTED_SERVICE_POINT', servicePoint)
  },
  setMyLocation({ commit }, location) {
    commit('SET_MY_LOCATION', location)
  },
  fetchMyLocation: ({ commit, state }) => {
    getMyLocation().then(location => {
      commit('SET_MY_LOCATION', location)
    })
  },
  fetchMyServicePoints: ({ commit, state }, payload) => {
    getMyServicePoints(payload.service_unit)
      .then(servicePoints => {
        commit('SET_SERVICE_POINTS', servicePoints)
        if (servicePoints.length) {
          commit('SET_SELECTED_SERVICE_POINT', servicePoints[0])
        }
      })
  },
  setQueueDate({ commit }, queueDate) {
    commit('SET_QUEUE_DATE', queueDate)
  },

}
