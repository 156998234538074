import state from './procedureState'
import mutations from './procedureMutations'
import actions from './procedureActions'
import getters from './procedureGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
