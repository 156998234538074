import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getScheduledOrUnscheduledprocedures = args => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.get_clinical_procedures_per_service_unit',
  args,
})

export const getClinicalProcedureTemplates = args => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.get_clinical_procedure_templates_per_service_unit',
  args,
})

export const scheduleProcedure = args => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.schedule_procedure',
  args,
})

export const getProcedureStatistics = args => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.get_procedure_statistics',
  args,
})

export const getClinicalProcedure = (procedure, args) => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.get_procedure',
  freeze: false,
  args: {
    procedure_name: procedure,
  },
})
export const getPatientNameAndCustomerNumber = patient => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.get_patient_name_and_number',
  freeze: false,
  args: {
    patient,
  },
})

export const editClinicalProcedure = args => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.edit_clinical_procedure',
  freeze: false,
  args,
})

export const commentTheatre = payload => api({
  method: 'clinical.api.clinical_procedure.clinical_procedure.add_theatre_schedule_comment',
  freeze: false,
  args: {
    payload,
  },
})
