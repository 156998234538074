export default {
  setContextItem({ commit }, payload) {
    commit('SET_CONTEXT_ITEM', payload)
  },

  setCurrentRoute({ commit }, payload) {
    commit('SET_CURRENT_ROUTE', payload)
  },
  showModal({ commit }, obj) {
    commit('SHOW_DIALOG', obj)
  },
}
