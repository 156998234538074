import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } etHomeRofrom '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'account-switcher' }, target: '_blank' },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    // ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  console.log('Route guard')
  const isLoggedIn = isUserLoggedIn()

  // if (to.path === '/career' || to.path.startsWith('/career/job/')) {
  //   return next()
  // }

  if (to.path ===  '/login' || to.path == "/register") {
    return next()
  }

  // Redirect to login if not logged in
  if (!isLoggedIn) return next({ name: 'auth-login' })

  return next()
})

export default router
