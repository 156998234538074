import Vue from 'vue'
import Vuex from 'vuex'
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import patient from './patient/modulePatient.js'
import procedures from './clinical_procedures/procedures'
import serviceUnit from './serviceUnit/moduleServiceUnit.js'
import servicePoint from './servicePoint/moduleServicePoint.js'
import socket from './socket/moduleSocket.js'
import notification from './notification/moduleNotification.js'
import supplier from './supplier/moduleSupplier.js'
import render from './render/module.js'
import accounts from './accounts'
import context from './context/index.js'
import encounter from './patient-encounter/module.js'
import appointments from './patient_appointments/module.js'
import app from '../../../store/app'
import appConfig from '../../../store/app-config'
import verticalMenu from '../../../store/vertical-menu'

window.process = {
  env: {
    NODE_ENV: 'production',
  },
}

Vue.use(Vuex)
export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    patient,
    serviceUnit,
    servicePoint,
    socket,
    supplier,
    render,
    notification,
    accounts,
    procedures,
    context,
    encounter,
    appointments,
  },
  strict: process.env.DEV,
})
