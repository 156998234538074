import { fetchNotifications, clearNotification } from './service'

export default {
  setNotificationList({ commit }, data) {
    return commit('SET_NOTIFICATION_LIST', data)
  },
  addNotification({ commit }, data) {
    return commit('ADD_NOTIFICATION', data)
  },

  fetchNotifications({ commit }, payload) {
    return fetchNotifications(payload)
      .then(notification => commit('SET_NOTIFICATION_LIST', notification))
      .catch(error => {
        console.log(error)
      })
  },

  clearNotification({ commit }, payload) {
    return clearNotification(payload)
      .then(notification => {
        if (notification.name) {
          return commit('REMOVE_NOTIFICATION', notification)
        }
        return commit('SET_NOTIFICATION_LIST', [])
      })
      .catch(error => {
        console.log(error)
      })
  },
}
