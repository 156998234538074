const APP_CONTEXT_MIXIN = {
  data() {
    return {}
  },
  methods: {
    contextUpdateEncounter(encounter) {
      this.$store.dispatch('encounter/setEncounter', encounter)
    },
    contextSetContextItem(payload) {
      this.$store.dispatch('context/setContextItem', payload)
    },
  },

  computed: {
    contextPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    contextServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    contextSocketData() {
      return this.$store.getters['socket/getSocketData']
    },
    contextPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    contextEncounter() {
      return this.$store.getters['encounter/getEncounter']
    },
  },
  watch: {
    contextPatient(val) {
      this.contextSetContextItem({ key: 'patient_name', value: val.patient })
    },
    contextServiceUnit(val) {
      if (val && val.service_unit) {
        this.contextSetContextItem({ key: 'service_unit_name', value: val.service_unit })
      }
    },

  },
}

export default APP_CONTEXT_MIXIN
