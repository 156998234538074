export default {
  SET_CONTEXT(state, context) {
    state.context = context
  },
  SET_CURRENT_ROUTE(state, context) {
    state.currentRoute = context
  },
  SET_CONTEXT_ITEM(state, contextItem) {
    state.context[contextItem.key] = contextItem.value
  },
  SHOW_DIALOG(state, obj) {
    state.modalData = obj
  },

}
