import base from './base'

export default {
  // Endpoints
  loginEndpoint: `${base.baseUrl}api/method/login`,
  registerEndpoint: `${base.baseUrl}api/method/hris.api.authentication.register`,
  activateUser: `${base.baseUrl}api/method/hris.api.authentication.activate_user`,
  changeUserPassowrd: `${base.baseUrl}api/method/hris.api.authentication.change_password`,
  jobOpenings: `${base.baseUrl}api/method/hris.api.job_application.get_job_opennings`,
  myJobOpenings: `${base.baseUrl}api/method/hris.api.job_application.get_my_job_applications`,
  resetPassword: `${base.baseUrl}api/method/hris.api.authentication.update_profile`,
  createJobApplicant: `${base.baseUrl}api/method/hris.api.job_application.create_applicant_profile`,
  applyJob: `${base.baseUrl}api/method/hris.api.job_application.apply_job`,
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  uploadFile: `${base.baseUrl}api/method/healthpro.api.file.upload.upload_file`,
  api: `${base.baseUrl}api/method/`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  withCredentials: true,

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
