// import { getSuppliersPerServiceUnit, transferSupplier as transferSupplierApi } from './.././../services/patient_chart/api';
// import { getSupplierInfo } from '../.././services/patient/patient.js'

export default {

  fetchSuppliers({ commit }, payload) {
    // return getSuppliersPerServiceUnit(payload)
    //     .then((patients) => {
    //         const inpatient_outpatient = [...patients.inpatients, ...patients.outpatients];
    //         console.log('FETCH SUCCESS', inpatient_outpatient);

    //         commit('SET_SUPPLIERS', inpatient_outpatient);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
  },

  fetchSelectedSupplierDetails({ commit }, payload) {
    console.log(payload)
    // return getSupplierInfo(payload)
    //     .then((patientData) => {
    //         commit('SET_SELECTED_SUPPLIER_DETAILS', patientData);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
  },

}
