import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPatient = ({ patient_name }) => api({
  method: 'billing.billing.api.accounts.patients.get_patient',
  args: {
    patient_name,
  },
})

export const getAccountsDashboard = ({
  patient_number = '',
}) => api({
  method: 'clinical.api.patient_accounts.customer_account_dashboard_info',
  args: {
    patient_number,
  },
})
