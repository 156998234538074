const getUserInfo = () => {
  const userInfo = {}

  return userInfo
}

const state = {
  AppActiveUser: getUserInfo(),
  tableData: [],
  ServicesBilling: [],
}

export default state
