export const FrappeMixin = {

  data() {
    return {
      inputValues: {},
      isDialogClosed: false,
      dialogTitle: 'Default',
      currentDialogData: {},
    }
  },
  watch: {
    bModal(val) {
      const open = !val.open && this.currentDialogData.title == this.dialogTitle
      if (open) {
        this.currentDialogData.primary_action(val.inputValues)
      }
    },
  },
  computed: {
    bModal() {
      return this.$store != null ? this.$store.getters['context/getModalData'] : {}
    },
  },
  methods: {
    handleClose() {
      const { dialogData } = this.bModal
      this.$store.dispatch('context/showModal', { dialogData, open: false, inputValues: this.inputValues })
    },
    handlePrimaryAction() {
      const { dialogData } = this.bModal
      this.$store.dispatch('context/showModal', { dialogData, open: false, inputValues: this.inputValues })
    },
    openDialog(dialogData) {
      this.dialogTitle = dialogData.title
      this.$store.dispatch('context/showModal', { dialogData, open: true, inputValues: this.inputValues })
    },
  },

}
