import Vue from 'vue'
import {
  FormFilePlugin , AlertPlugin, PaginationPlugin, PopoverPlugin, TooltipPlugin, IconsPlugin, ToastPlugin, ModalPlugin, ButtonPlugin, LayoutPlugin, OverlayPlugin, FormDatepickerPlugin, CardPlugin, TablePlugin, FormSelectPlugin, FormRadioPlugin, TimePlugin, ButtonGroupPlugin, FormGroupPlugin, FormInputPlugin, SidebarPlugin, FormCheckboxPlugin, DropdownPlugin, TabsPlugin, CollapsePlugin, ListGroupPlugin, BadgePlugin, AvatarPlugin, FormTextareaPlugin, BFormTextarea,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { VueFormBuilderPlugin } from 'vue-frappe-formbuilder-health'
import 'vue-frappe-formbuilder-health/dist/v-form-builder.css'
import vSelect from 'vue-select'
import Vuex from 'vuex'

import i18n from '@/libs/i18n'
import DoctypeLink from '@/views/components/doctype-link/DoctypeLink.vue'
import ScrollWrapper from '@/views/erp/components/ScrollWrapper.vue'
import router from './router'
import store from './views/erp/state/store'
import App from './App.vue'
import { BootstrapVueToasterMixin } from './global-mixins'
import { FrappeMixin } from './frappe-mxin'
import { APP_CONTEXT_MIXIN } from './views/erp/mixins/app-context.mixin'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db' // For form-wizard
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import './registerServiceWorker'
import bottomNavigationVue from "bottom-navigation-vue";
import FormRenderView from '@/views/components/formbuilder/Frm.vue'

// vuex
Vue.use(Vuex)

// BSV Plugin Registration
Vue.component('v-select', vSelect)
Vue.component('t-frm', FormRenderView)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueFormBuilderPlugin)
Vue.use(LayoutPlugin)
Vue.use(OverlayPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(CardPlugin)
Vue.use(TablePlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormRadioPlugin)
Vue.use(TimePlugin)
Vue.use(ButtonGroupPlugin)
Vue.mixin(BootstrapVueToasterMixin)
// Vue.mixin(APP_CONTEXT_MIXIN)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(SidebarPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(DropdownPlugin)
Vue.use(PopoverPlugin)
Vue.use(IconsPlugin)
Vue.use(TabsPlugin)
Vue.use(CollapsePlugin)
Vue.use(ListGroupPlugin)
Vue.use(TooltipPlugin)
Vue.mixin(FrappeMixin)
Vue.use(BadgePlugin)
Vue.use(AvatarPlugin)
Vue.use(PaginationPlugin)
Vue.use(AlertPlugin)
Vue.use(bottomNavigationVue);
Vue.use(FormFilePlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.component('doctype-link', DoctypeLink)
Vue.component('wrapper', ScrollWrapper)

Vue.filter('truncate', function (text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
});

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/Inter/inter.scss')
require('@/assets/scss/style.scss')

Vue.config.silent = true
Vue.config.devtools = true

Vue.filter('subtitle', unit => {
  if (
    unit
        && unit.inpatient_occupancies
        && unit.inpatient_occupancies.length > 0
  ) {
    return unit.inpatient_occupancies[0].service_unit
  } if (unit && unit.patient_name) {
    return unit.patient_name
  }
  return ''
})

Vue.filter('nameInitials', fullName => fullName
  .split(' ')
  .map((name, index) => (name && index < 1 ? name[0] : ''))
  .join(''))

Vue.filter('noSnake', stringItem => {
  if (!stringItem) {
    return
  }
  const noSnakeString = stringItem.replace('_', ' ')
  return noSnake.charAt(0).toUpperCase() + noSnakeString.slice(1)
})

// format date
Vue.filter('filterDate', value => {
  const year = value.substring(0, 4)
  const month = value.substring(5, 7)
  const day = value.substring(8, 10)
  return `${day}-${month}-${year}`
})

// format time
Vue.filter('filterTime', value => {
  const time = value.substring(11, 16)
  return time
})

Vue.config.productionTip = false

// Get the current protocol (HTTP or HTTPS)
const { protocol } = window.location

// Get the current site domain
const domain = window.location.hostname

// Combine protocol and domain
const fullURL = `${protocol}//${domain}`
// fullURL ="https://philip.erpnextkenya.com"
localStorage.setItem('appBaseUrl', fullURL)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
