export default {
  procedureTemplates: [],
  scheduledProcedures: [],
  unScheduledProcedures: [],
  procedureStatistics: [],
  queryParams: {},
  pagination: {
    per_page: 12,
    current_page: 1,
    page_count: 12,
  },
}
