export default {
  ADD_NOTIFICATION(state, notification) {
    const exits = state.notificationList.find(item => item.name == notification.name)
    if (!exits) {
      state.notificationList.unshift(notification)
    }
  },
  SET_NOTIFICATION_LIST(state, data) {
    state.notificationList = data
  },
  UPDATE_NOTIFICATION(state, notification) {
    const notificationIndex = state.notificationList.findIndex(
      t => t.name == notification.name,
    )
    Object.assign(state.notificationList[notificationIndex], notification)
  },
  REMOVE_NOTIFICATION(state, notification) {
    state.notificationList = state.notificationList.filter(
      item => item.name !== notification.name,
    )
  },
}
