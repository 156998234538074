import { getScheduledOrUnscheduledprocedures, getClinicalProcedureTemplates, getProcedureStatistics } from './service'

export default {
  fetchProcedures({ commit }, query) {
    return getScheduledOrUnscheduledprocedures(query)
      .then(({ scheduled_procedures, un_scheduled_procedures, pagination }) => {
        commit('SET_UNSCHEDULED_PROCEDURES', un_scheduled_procedures)
        commit('SET_SCHEDULED_PROCEDURES', scheduled_procedures)
        commit('SET_PAGINATION_META', pagination)
      })
  },
  fetchProcedureTemplates({ commit }, args) {
    console.log('Mes Templates', args)
    return getClinicalProcedureTemplates(args).then(templates => {
      commit('SET_PROCEDURE_TEMPLATES', templates)
    })
  },
  setQueryParams({ commit }, payload) {
    console.log('Etudes ', payload)
    return commit('SET_QUERY_PARAMS', payload)
  },

  fetchProcedureStatistics({ commit }, args) {
    return getProcedureStatistics(args)
      .then(procedures => {
        commit('SET_PROCEDURE_STATISTICS', procedures)
      })
  },
}
