<template>
  <b-row class="w-100 mw-100">
    <v-select
      v-model="selectedItem"
      class="w-100 mw-100"
      :options="options"
      label="name"
      :placeholder="placeholder"
      :filterable="false"
      @search="fetchData"
      @input="selectItem"
      @search:focus="onFocus"
    >
      <template #selected-option="{ value }">
        <div style="display: flex; align-items: baseline">
          <strong>{{ value }}</strong>
        </div>
      </template>
      <template
        slot="selected-option"
        slot-scope="option"
      >
        <div class="selected d-center">
          <b-row>
            <b class="w-100"> {{ option.value }}</b>
            <span v-if="option.value != option.name" style="font-size: 12px;">  {{ option.name }}</span>
          </b-row>
     
        </div>
      </template>

      <template
        slot="option"
        slot-scope="option"
      >
        <div class="d-center">
          <b> {{ option.value }}</b>  <span v-if="option.value != option.name"> - {{ option.name }} </span> 
        </div>
      </template>
    </v-select>
  </b-row>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    doctype: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    api: {
      type: String,
    },
    filters: {

    },
  },
  data() {
    return {
      options: [],
      selectedItem: null,
    }
  },
  mounted() {

  },
  methods: {
    async onFocus() {
      if (this.options.length === 0 && this.selectItem.length == 0) {
        const result = await this.fetchData(' ', () => {})
      }
    },
    async fetchData(search, loading) {
      try {
        if (loading !== null) {
          loading(true)
        }

        const response = await useJwt.direct_api(
          'frappe.desk.search.search_link',
          {
            txt: search,
            doctype: this.doctype,
            ignore_user_permissions: 1,
            query: this.api,
            //   reference_doctype: 'Clinical Procedure Item',
            filters: this.filters,
          },
        )
        this.options = response.data.results.map(item => ({
          value: item.value,
          name: item.description,
        }))
        if (loading !== null) {
          loading(false)
        }
      } catch (error) {
        console.error(error)
        if (loading !== null) {
          loading(false)
        }
      }
    },
    selectItem() {
      this.$emit('selected', this.selectedItem.value)
    },
  },
}
</script>

<style>
/* Add your custom styles here */
</style>
