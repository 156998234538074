import { getMyServiceUnits } from './service'

export default {
  setSelectedServiceUnit({ commit }, serviceUnit) {
    commit('SET_SELECTED_SERVICE_UNIT', serviceUnit)
  },

  fetchMyServiceUnits: ({ commit, state }, payload) => {
    getMyServiceUnits()
      .then(serviceUnits => {
        commit('SET_SERVICE_UNITS', serviceUnits)
        if (!state.selectedServiceUnit.name) {
          if (serviceUnits.length) {
            commit('SET_SELECTED_SERVICE_UNIT', serviceUnits[0])
          }
        }
      })
  },
}
