export default {
  SET_SERVICE_POINTS(state, servicePoints) {
    state.servicePoints = servicePoints
  },
  SET_SELECTED_SERVICE_POINT(state, selectedServicePoint) {
    state.selectedServicePoint = selectedServicePoint
  },
  SET_MY_LOCATION(state, selectedLocation) {
    state.myLocation = selectedLocation
  },
  SET_QUEUE_DATE(state, selectedDate) {
    state.queueDate = selectedDate
  },
}
