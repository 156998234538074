import { getPatientsPerServiceUnit, transferPatient as transferPatientApi, getPatientInfo } from './service'

export default {
  setPatientSearchQuery({ commit }, query) {
    commit('SET_PATIENT_SEARCH_QUERY', query)
  },

  fetchPatients({ commit }, payload) {
    console.log('BACK AT IT', payload)
    if (!payload.serviceUnit) {
      return
    }
    payload.work = undefined
    return getPatientsPerServiceUnit(payload)
      .then(patients => {
        const inpatient_outpatient = [...patients.inpatients, ...patients.outpatients]
        console.log('FETCH SUCCESS', inpatient_outpatient)

        commit('SET_PATIENTS', inpatient_outpatient)
      })
      .catch(error => {
        console.log(error)
      })
  },
  transferPatient: ({ commit }, { patient, callback, ...transfer_payload }) => transferPatientApi(transfer_payload).then(() => {
    const newPatient = { ...patient, bed: transfer_payload.service_unit, service_unit: transfer_payload.service_unit }
    commit('UPDATE_PATIENT', newPatient)
    callback()
  }),

  setSelectedPatient({ commit }, patient) {
    return commit('SET_SELECTED_PATIENT', patient)
  },
  fetchSelectedPatientDetails({ commit }, payload) {
    console.log(payload)
    if (payload.patient_name) {
      return getPatientInfo(payload)
        .then(patientData => {
          commit('SET_SELECTED_PATIENT_DETAILS', patientData)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },

  setTimelineData({ commit }, timelineData) {
    return commit('SET_TIMELINE_DATA', timelineData)
  },

}
